.wave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.wave svg {
  position: relative;
  display: block;
  width: calc(115% + 1.3px);
  height: 140px;
}

.wave .shapefill {
  fill: #ffeedc;
}
